import { useCallback, useEffect, useRef, useState } from "react"
import { useField } from "react-final-form"
import { translate, Translate } from "react-i18nify"
import {
  Flex,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react"
import { useCreateFile } from "hooks"
import { ImageApi } from "components/common"
import { Control, Error } from "components/form-fields"
import { constants, resizeImage } from "utils"

export const ImageUploadControl = ({
  name,
  label,
  onLoad,
  placeholder = null,
  acceptedFileTypes = "image/*",
  allowRemove = true,
  required = false,
  ...rest
}) => {
  const { input, meta } = useField(name)
  const inputRef = useRef()
  const { colorMode } = useColorMode()
  const toast = useToast()
  const { mutate: createFile, isLoading: createlFileLoading } = useCreateFile()
  const [current, setCurrent] = useState({ src: null, value: input?.value })
  const isDark = colorMode === "dark"
  const fontColor = isDark ? "white" : "black"
  const bgColor = isDark ? "gray.700" : "gray.300"

  useEffect(() => {
    setCurrent((prev) => ({ ...prev, value: input?.value }))
  }, [input?.value, setCurrent])

  useEffect(() => {
    onLoad(name, createlFileLoading)
  }, [createlFileLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = useCallback(async () => {
    const file = inputRef.current.files && inputRef.current.files[0]
    let image = null
    if (file) {
      try {
        image = await resizeImage(file)
      } catch (err) {
        console.error(err)
        toast({
          title: translate("pages.save.resizing_error"),
          description: err?.message,
          status: "error",
          duration: constants.TOAST_ERROR_DURATION,
          isClosable: true,
        })
      }
    }
    if (image) {
      let reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onloadend = (event) => {
        // on affiche l'image (redimensionnée) avant/pendant l'upload
        setCurrent((prev) => ({ ...prev, src: event.target.result }))
      }
      createFile(
        { file: image, filename: `asset-${name}.jpeg` },
        {
          onSuccess: (files) => {
            const newValue = {
              ...input?.value,
              position: name,
              file: files && files[0],
            }
            setCurrent((prev) => ({
              ...prev,
              value: newValue,
            }))
            // on appelle le onChange de l'input pour mettre à jour la valeur
            input.onChange(newValue)
          },
        }
      )
    }
  }, [name, input, createFile, setCurrent, toast])

  const onRemove = useCallback(() => {
    setCurrent({
      src: null,
      value: null,
    })
    input.onChange(null)
  }, [input])

  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{required ? `${label} *` : label}</FormLabel>
      <Flex
        position="relative"
        justifyContent="center"
        alignItems="center"
        w={200}
        h={200}
        mx="auto"
        color={fontColor}
        bg={current.src || current.value?.file ? "none" : bgColor}
        overflow="hidden"
        onClick={() => inputRef.current.click()}
      >
        <Input
          id={name}
          type="hidden"
          display="none"
          isInvalid={meta.error && meta.touched}
          {...input}
          {...rest}
        />
        <Input
          id={`${name}-file`}
          ref={inputRef}
          type="file"
          accept={acceptedFileTypes}
          display="none"
          onChange={onChange}
        />
        {(current.src || current.value?.file) && (
          <ImageApi
            temp={current.src}
            file={current.value?.file}
            alt={placeholder || label}
            loading={createlFileLoading}
            onRemove={allowRemove ? onRemove : null}
          />
        )}
        <Text
          position="absolute"
          left="50%"
          top="50%"
          width={150}
          px={3}
          py={2}
          textAlign="center"
          color="white"
          bg="blackAlpha.600"
          transform="translate(-50%, -50%)"
        >
          {placeholder || label}
        </Text>
      </Flex>
      <Error name={name} justifyContent="center" />
      <FormHelperText textAlign="center">
        <Translate value="pages.save.click_to_upload" />
      </FormHelperText>
    </Control>
  )
}
